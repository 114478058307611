.spinner-wrap {
  align-items: center;
  justify-content: center;
  position: fixed;
  width: calc(100% - 258px);
  z-index: 200;
  left: 258px;
  top: 56px;
  bottom: 0;
  right: 0;
  background: rgba($ui01, 1);
  height: calc(100vh - 64px);
  display: flex;
}

.suspense-spinner-wrap {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.spinner {
  width: 66px;
  height: 66px;
  animation: preloader 2s linear infinite;

  svg {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
  }
}

.spinner svg:nth-child(1) circle {
  stroke: rgba($neutral03, 1);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (1)) ease infinite;
  transform-origin: center center;
}
.spinner svg:nth-child(2) circle {
  stroke: rgba($neutral03, 1);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (2)) ease infinite;
  transform-origin: center center;
}
.spinner svg:nth-child(3) circle {
  stroke: rgba($supportWarning01, 1);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (3)) ease infinite;
  transform-origin: center center;
}
.spinner svg:nth-child(4) circle {
  stroke: rgba($neutral01, 1);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (4)) ease infinite;
  transform-origin: center center;
}

@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.54832;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.64497;
  }
}
@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
